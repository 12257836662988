<template>
  <div></div>
</template>

<script>
import userModel from "../../api/user";

export default {
  data() {
    return {
      shangjias: JSON.parse(sessionStorage.getItem("shangjia")),
    };
  },
  methods: {},
  created() {
    sessionStorage.setItem("isokcofig", true);
    localStorage.setItem("isok", true);
    localStorage.removeItem("userInfo");
    // if (sessionStorage.getItem("key")) {
    //   // 进行返回逻辑
    //   sessionStorage.removeItem("key");
    //   var u =window.navigator.userAgent
    //   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
    //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //   parent.WeixinJSBridge.call("closeWindow");
    //   return
    // }else{

    // }
    if (this.$route.query.code) {
      //如果连接中能拿到code说明此时access_token没有或者已过期，需要重新获取token

    console.log("sessionStorage-gid===",sessionStorage.getItem("gid"))
    console.log("localStorage-gid===",localStorage.getItem("gid"))
      userModel
        .auth({
          code: this.$route.query.code,
          gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : localStorage.getItem("gid"),
        })
        .then((res) => {
          if (res.code == 200) {
            // sessionStorage.setItem("key", this.$route.query.code);
            localStorage.setItem("Authorization", res.data.authorization);
            localStorage.setItem("carNo", res.data.carNo);
            sessionStorage.setItem(
              "chexin",
              JSON.stringify(res.data.mallUserCarInfoDO)
            );
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            // let url = localStorage.getItem("beforeUrl")
            let url = localStorage.getItem("beforeUrl");

            // this.$router.push({
            //   path: "user"
            // });
            this.$router.push(url);
            // window.location.replace(url);
            // this.$router.go(-1);
          } else {
            this.Toast(res.data.msg);
          }
        });
    }
  },
};
</script>
